/* 
 * Pendo guides do not show correctly on mobile devices and small screens.
 * so these styles are needed to make the guide related to closed
 * consults display correctly for screens smaller than 500 pixels.
 */
@media screen and (max-width: 500px) {
	#pendo-g-LVvNeGwq81aw4XAou0AFEG_xKxo {
		max-width: 95%;
		margin-left: auto !important;
		margin-right: auto !important;
		left: 0 !important;
		right: 0 !important;
	}

	#pendo-select-container-9bcf2e79 label.pendo-radio {
		margin-right: 0 !important;
		max-width: 80%;
		text-align: left !important;
	}
}
